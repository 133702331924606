import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'

import Logo from '../../images/mineral_swim_by_maytronics.svg'

import '../../index.css'
import '../../old.css'

import Header from '../Header'
import ActionBar from '../ActionBar'
import Footer from '../Footer'

/**
 * Layout - Wrapping your pages in top level goodness
 * @param {Object} props
 * @param {Node} props.children
 */
const Layout = ({ children, data }) => {
	return (
		<div>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Mineral Swim - Experience The Dead Sea</title>
				<meta
					name="description"
					content="Mineral Swim Is A Truly Unique Swimming Experience. Providing An Authentic Dead Sea Experience With Thousands Of Years Of Health And Beauty Straight Into Your Pool. Maytronics, A Leading Supplier To The Pool Industry For Over 35 Years, Brings The Highest Quality Minerals To Recreate An Authentic Dead Sea Experience In Your Very Own Pool."
				/>
				<meta name="keywords" content="Mineral Swim" />
				<link rel="canonical" href="https://mineralswim.com/" />
				<meta name="twitter:site" content="@MaytronicsAu" />
				<meta name="og:type" content="website" />
				<meta
					name="og:site_name"
					content="Mineral Swim by Maytronics - Experience The Dead Sea"
				/>
				<html lang="en-AU" />
				<meta name="theme-color" content="#008599" />
				<meta
					name="apple-mobile-web-app-status-bar-style"
					content="black-translucent"
				/>
				<meta name="application-name" content="Mineral Swim" />
				<meta name="msapplication-TileColor" content="#FFFFFF" />
				<link rel="prefetch" href={Logo}></link>
				<link rel="prefetch" href="./fonts/inter/index.css"></link>
				<link rel="prefetch" href="./fonts/flexo/index.css"></link>
			</Helmet>
			<div className="bg-white grid w-full" style={{ height: 63 }}>
				<div className="fixed bg-white w-full z-40 ">
					<Header logo={Logo} />
				</div>
				{/* <ActionBar /> */}
			</div>
			{children}
			<Footer />
		</div>
	)
}

Layout.propTypes = {
	data: PropTypes.any,
	children: PropTypes.node.isRequired,
}

export default Layout
