import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout/old'

const NotFoundPage = () => (
	<Layout className="container">
		<Helmet>
			<title>Page NOT FOUND: 404</title>
			<meta
				name="description"
				content="Mineral Swim Is A Truly Unique Swimming Experience. Providing An Authentic Dead Sea Experience With Thousands Of Years Of Health And Beauty Straight Into Your Pool. Maytronics, A Leading Supplier To The Pool Industry For Over 35 Years, Brings The Highest Quality Minerals To Recreate An Authentic Dead Sea Experience In Your Very Own Pool."
			/>
			<meta name="og:site_name" content="Page NOT FOUND: 404" />
			<meta name="keywords" content="Mineral Swim, 404" />
			<link rel="canonical" href="https://mineralswim.com/404" />
		</Helmet>
		<div className="content">
			<h1>NOT FOUND</h1>
			<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
			{/* <form
				name="contact"
				method="POST"
				data-netlify="true"
				data-netlify-honeypot="bot-field"
				// netlify-recaptcha
			>
				<input type="hidden" name="form-name" value="contact" />
				<p className="is-hidden">
					<label htmlFor="robot">
						Don’t fill this out if you’re human:{' '}
						<input id="robot" name="bot-field" />
					</label>
				</p>
				<input type="text" name="firstName" />
				<input type="text" name="lastName" />
				<input type="email" name="email" />
				<input type="tel" name="mobile" />
				<input type="tel" name="postcode" />
				<input type="text" name="recaptcha" />
				<textarea name="message" />
				<button aria-label="Send" type="submit">
					Send
				</button>
			</form> */}
		</div>
	</Layout>
)

export default NotFoundPage
